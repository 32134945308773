<template>
  <div class="Notifications" >
    <div class="container">
      <h1 class="gavc-h1 text-center mb-4">Mes notifications</h1>

      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="notification gavc-bg-bizarre mt-2"
        @click="clickOnNotification(notification)"
      >
        <div>
          <small class="text-muted float-right">{{calculateDateDifference(notification.timestamp)}}</small>
        </div>
        <div v-bind:class="['text-truncated', (!notification.clicked) ? 'font-weight-bold' : 'text-muted']">
          <span v-if="!notification.clicked" class="notification-dot mr-2"></span>{{notificationTitleRender(notification.verb)}}
        </div>
        <div class="text-truncated small text-muted mt-1">
          {{notificationDescriptionRender(notification)}}
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 text-center">
          <button
            v-if="getAllNotificationsUrl"
            @click="getAllNotifications()"
            class="gavc-btn gavc-btn--bone">
            Voir plus
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'
import notificationsMixins from '@/mixins/notificationsMixins'
import { APIConnected } from '@/api/APIConnected'

const apiConnectedService = new APIConnected()

export default {
  name: 'Notifications',
  mixins: [
    notificationsMixins
  ],
  data: function () {
    const now = this.$dayjs()
    return {
      now: now,
      // ------ Notifications  ---
      notifications: [],
      baseGetAllNotificationsUrl: apiBaseUrl + '/all-own-notifications',
      getAllNotificationsUrl: apiBaseUrl + '/all-own-notifications',
    }

  },
  computed: {
    token () {
      return this.$store.state.auth.token
    }
  },
  created: function() {
    this.getAllNotifications()
  },
  methods: {

    getAllNotifications () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAllNotifications(this.token, this.getAllNotificationsUrl)
      .then((result) => {
        this.notifications = this.notifications.concat(result.data.results)
        this.getAllNotificationsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    clickOnNotification (notification) {
      if (notification.clicked) {
        this.$router.push(this.notificationLinkRender(notification.actor, notification.verb))
      }
      else {
        apiConnectedService.markNotificationAsClicked(this.token, notification.id)
        .then(() => {
          this.$router.push(this.notificationLinkRender(notification.actor, notification.verb))
        })
      }
    },
    calculateDateDifference(notification_date) {
      return this.notificationDateRender((this.now - this.$dayjs(notification_date))/1000)
    }
  }
}
</script>

<style scoped>
.notification {
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.notification:hover {
  background-color: #FFE7D1;
}
</style>
